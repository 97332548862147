import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout/Layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const InstagramFeed = makeShortcode("InstagramFeed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Travelling Photography" mdxType="SEO" />
    <h1>{`Travelling Photography`}</h1>
    <p>{`I love travelling and I have a goal of visiting 100 countries this decade.`}</p>
    <p>{`During my travels, I snap photographs; Some mementos, others relics and inevitably they end up on my `}<a parentName="p" {...{
        "href": "https://www.instagram.com/samuel.gordalina"
      }}>{`instagram feed`}</a>{` with the appropriate filter.`}</p>
    <InstagramFeed mdxType="InstagramFeed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      